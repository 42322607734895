// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academia-js": () => import("/opt/build/repo/src/pages/academia.js" /* webpackChunkName: "component---src-pages-academia-js" */),
  "component---src-pages-business-js": () => import("/opt/build/repo/src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-government-js": () => import("/opt/build/repo/src/pages/government.js" /* webpackChunkName: "component---src-pages-government-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-js": () => import("/opt/build/repo/src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-technology-js": () => import("/opt/build/repo/src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */)
}

